<template>
  <div class="pre-formatted">
    <div id="faq-page" >
      <!-- JUMBOTRON -->
      <div class="faq-jumbotron">
        <div
          class="ph-banner faq-jumbotron-content text-center lg:p-32 md:p-24 sm:p-16 py-16 px-8"
          :style="{ backgroundImage: `url(${heroBannerContent.image})` }"
        >
          <div
            class="ph-banner-inner-content flex items-center justify-center flex-wrap h-full"
          >
            <div>
              <h1 class="mb-10 text-white text-bold w-full flex-center">
                {{ heroBannerContent.title }}
              </h1>
              <vs-button @click="buttonHandler('heroBanner1')" align="center">{{
                heroBannerContent.buttonText
              }}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-section py-10 md:py-20">
      <div class="container">
        <div class="text-center flex-wrap">
          <h1 class="mb-16">{{ textSection.title }}</h1>
          <p class="md:w-3/4 mx-auto">{{ textSection.description }}</p>
        </div>
      </div>
    </div>
    <div id="package-section" class="pt-10 pb-20">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        class="w-full"
      >
        <!--        basic package card-->
        <div class="container">
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full md:w-1/3 mb-5 md:mb-0"
          >
            <vs-card class="package-card m-0 md:mx-5">
              <div class="heading">
                <h3 class="m-5">{{ basicPackage.name | capitalize }}</h3>
                <h1 class="text-bold m-5">${{ basicPackage.price }}</h1>
                <p class="m-5">/ {{ basicPackage.quotaText }}</p>
              </div>
              <hr />
              <!--            <div>-->
              <!--              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full 5d61zS">-->
              <!--                <vs-col vs-type="flex" vs-justify="center" vs-align="left"  class="w-1/2 my-2 px-5">-->
              <!--                  <p>{{basicPackage.numberOfCenter}} center</p>-->
              <!--                </vs-col>-->
              <!--                <vs-col vs-type="flex" vs-justify="center" vs-align="left" class="last-child w-1/2 my-2 px-5">-->
              <!--                  <p>{{basicPackage.numberOfChildren}} children</p>-->
              <!--                </vs-col>-->
              <!--              </vs-row>-->
              <!--            </div>-->
              <!--            <hr>-->
              <div>
                <ul class="opt mt-4 mb-4 px-5">
                  <li
                    v-for="option in options"
                    :key="option.id"
                    class="py-2 font-medium flex items-center"
                    @click="faqFilter = option.id"
                    v-if="checkInclusive(option._id, basicPackage.features)"
                  >
                    <div class="round">
                      <i class="material-icons">check_circle</i>
                      <span class="cursor-pointer">{{ option.name }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <vs-button
                type="border"
                class="w-full"
                @click="buttonHandler('basic')"
                >Buy Now</vs-button
              >
            </vs-card>

            <!--          advanced package card-->
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full md:w-1/3 mb-5 md:mb-0"
          >
            <vs-card class="package-card package-card-advanced m-0 md:mx-5">
              <div class="value">
                <span class="w-full text-white font-semibold" align="center"
                  >Great Value</span
                >
              </div>
              <div class="heading">
                <h3 class="m-5">{{ advancePackage.name | capitalize }}</h3>
                <h1 class="text-bold m-5">${{ advancePackage.price }}</h1>
                <p class="m-5">/ {{ advancePackage.quotaText }}</p>
              </div>
              <hr />
              <!--            <div>-->
              <!--              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full text-center">-->
              <!--                <vs-col vs-type="flex" vs-justify="center" vs-align="left"  class="w-1/2 my-2 px-5">-->
              <!--                  <p>{{advancePackage.numberOfCenter}} centers</p>-->
              <!--                </vs-col>-->
              <!--                <vs-col vs-type="flex" vs-justify="center" vs-align="left" class="last-child w-1/2 my-2 px-5">-->
              <!--                  <p>{{ advancePackage.numberOfChildren }} children</p>-->
              <!--                </vs-col>-->
              <!--              </vs-row>-->
              <!--            </div>-->
              <!--            <hr>-->
              <div>
                <ul class="opt mt-4 mb-4 px-5">
                  <li
                    v-for="option in options"
                    :key="option.id"
                    class="py-2 font-medium flex items-center"
                    @click="faqFilter = option.id"
                    v-if="checkInclusive(option._id, advancePackage.features)"
                  >
                    <div class="round">
                      <i class="material-icons">check_circle</i>
                      <span class="cursor-pointer">{{ option.name }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <vs-button class="w-full mt-5" @click="buttonHandler('advance')"
                >Buy Now</vs-button
              >
            </vs-card>

            <!--          corporate package card-->
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full md:w-1/3 mb-5 md:mb-0"
          >
            <vs-card class="package-card m-0 md:mx-5">
              <div class="heading">
                <h3 class="m-5">{{ corporatePackage.name | capitalize }}</h3>
                <h1 class="text-bold m-5">${{ corporatePackage.price }}</h1>
                <p class="m-5">/ {{ corporatePackage.quotaText }}</p>
              </div>
              <hr />
              <!--            <div>-->
              <!--              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full text-center">-->
              <!--                <vs-col vs-type="flex" vs-justify="left" vs-align="left"  class="w-1/2 my-2 px-5">-->
              <!--                  <p>{{ corporatePackage.numberOfCenter }} Centers</p>-->
              <!--                </vs-col>-->
              <!--                <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="last-child w-1/2 my-2 px-5">-->
              <!--                  <p>{{ corporatePackage.numberOfChildren }} children</p>-->
              <!--                </vs-col>-->
              <!--              </vs-row>-->
              <!--            </div>-->
              <!--            <hr>-->
              <div>
                <ul class="opt mt-4 mb-4 px-5">
                  <li
                    v-for="option in options"
                    :key="option.id"
                    class="py-2 font-medium flex items-center"
                    @click="faqFilter = option.id"
                    v-if="checkInclusive(option._id, corporatePackage.features)"
                  >
                    <div class="round">
                      <i class="material-icons">check_circle</i>
                      <span class="cursor-pointer">{{ option.name }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <vs-button
                type="border"
                class="w-full"
                @click="buttonHandler('corporate')"
                >Buy Now</vs-button
              >
            </vs-card>
          </vs-col>
        </div>
      </vs-row>
    </div>
    <div id="banner-page">
      <!-- JUMBOTRON -->
      <div class="faq-jumbotron">
        <div class="faq-jumbotron-content lg:py-32 md:py-24 sm:py-16 py-8">
          <div class="container text-center">
            <h1 class="mb-4 text-black text-bold w-full flex-center">
              {{ bannerContent.title }}
            </h1>
            <p class="my-10 md:my-16 mx-auto w-full md:w-3/4">
              {{ bannerContent.description }}
            </p>
            <vs-button
              @click="buttonHandler('heroBanner2')"
              align="center"
              color="white"
              text-color="black"
              class="large"
              >{{ bannerContent.buttonText }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
    <div id="contact-section" class="contact-section-wrap py-10 md:py-20">
      <div class="container">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full md:w-1/2"
          >
            <div class="contact-section-card">
              <h1 class="mb-10 text-bold w-full flex-center">
                {{ contactSection.title }}
              </h1>
              <p v-html="parsedHtml(contactSection.description)" ></p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full md:w-1/2"
          >
            <div class="contact-form-section">
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-1/2"
                >
                  <div class="w-full m-2">
                    <vs-input
                      label-placeholder="First Name"
                      v-model="formData.firstName"
                      class="w-full"
                      name="firstName"
                      v-validate="'required|alpha_spaces'"
                      data-vv-as="First Name"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("firstName")
                    }}</span>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-1/2"
                >
                  <div class="w-full m-2">
                    <vs-input
                      label-placeholder="Last Name"
                      v-model="formData.lastName"
                      class="w-full"
                      name="lastName"
                      v-validate="'required|alpha'"
                      data-vv-as="Last Name"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("lastName")
                    }}</span>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-full"
                >
                  <div class="w-full m-2">
                    <vs-input
                      label-placeholder="Email"
                      v-model="formData.email"
                      class="w-full"
                      name="email"
                      v-validate="'required|email'"
                      data-vv-as="Email"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("email")
                    }}</span>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-full"
                >
                  <div class="w-full m-2">
                    <vs-input
                      label-placeholder="Phone"
                      v-model="formData.phoneNumber"
                      class="w-full"
                      name="phoneNumber"
                      v-validate="'required|numeric'"
                      data-vv-as="Phone Number"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("phoneNumber")
                    }}</span>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-1/2"
                >
                  <div class="w-full m-2">
                    <vs-input
                      label-placeholder="Number of enrolled children"
                      v-model="formData.children"
                      class="w-full"
                      name="children"
                      v-validate="'required|numeric'"
                      data-vv-as="Number of enrolled children"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("children")
                    }}</span>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-1/2"
                >
                  <div class="w-full m-2">
                    <vs-input
                      label-placeholder="Number of centers"
                      v-model="formData.centres"
                      class="w-full"
                      name="centres"
                      v-validate="'required|numeric'"
                      data-vv-as="Number of centers"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("centres")
                    }}</span>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-full"
                >
                  <div class="w-full m-2 contact-textarea">
                    <vs-textarea
                      placeholder="Message"
                      v-model="formData.message"
                      class="w-full"
                      name="message"
                    />
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  class="w-full"
                >
                  <div class="w-full m-2">
                    <vs-button
                      class="w-full"
                      :disabled="!isFormValid"
                      @click="submitHandler"
                      >Submit</vs-button
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <div class="faq-wrap py-10 md:py-20" id="faq-section">
      <div class="container">
        <h1 class="mb-16">FAQ</h1>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table ref="table" :sst="true" :data="faqData">
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.title">
                  {{ tr.title | capitalize }}
                </vs-td>
                <vs-td class="whitespace-no-wrap text-center">
                  <feather-icon
                    icon="ChevronDownIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </vs-td>

                <template slot="expand">
                  <div>
                    <p  v-html="parsedHtml(tr.description)" class="pre-formatted"></p>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

export default {
  data() {
    return {
      isLogin: false,
      heroBannerContent: {
        image: "",
        buttonText: "",
        buttonUrl: "",
        title: ""
      },
      textSection: {
        title: "",
        description: ""
      },
      bannerContent: {
        // image:'',
        buttonText: "",
        buttonUrl: "",
        title: "",
        description: ""
      },
      contactSection: {
        title: "",
        description: ""
      },
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        children: "",
        centres: "",
        message: ""
      },
      faqData: [],
      basicPackage: "",
      advancePackage: "",
      corporatePackage: "",
      expandData: false,
      options: []
    };
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.formData.firstName &&
        this.formData.lastName &&
        this.formData.email &&
        this.formData.phoneNumber &&
        this.formData.children &&
        this.formData.centres
      );
    },

  },
  methods: {
    ...mapActions("pages", [
      "fetchContentBySlug",
      "fetchPackageInfo",
      "fetchFaqDetails",
      "submitContactInfo",
      "fetchInclusionList"
    ]),
    parsedHtml (text) {
            const parser = new DOMParser();
            const elem = parser.parseFromString(text, 'text/html');

            return elem.body.innerText;
        },
    checkInclusive(inclusiveId, features) {
      if (features.indexOf(inclusiveId) != -1) {
        return true;
      }

      return false;
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    buttonHandler(key) {
      if (key === "herobanner1") {
        this.$router.push(this.heroBannerContent.buttonUrl);
      }
      if (key === "herobanner2") {
        this.$router.push(this.bannerContent.buttonUrl);
      }

      if (this.isLogin) {
        if (key === "basic") {
          this.$router.push({
            name: "director-payment-details",
            params: { type: "basic" },
            query: { to: "/payment-details" }
          });
        }
        if (key === "advance") {
          this.$router.push({
            name: "director-payment-details",
            params: { type: "advance" }
          });
        }
        if (key === "corporate") {
          this.$router.push({
            name: "director-payment-details",
            params: { type: "corporate" }
          });
        }
      } else {
        if (key === "basic") {
          this.$router.push({
            name: "director-signup",
            params: { type: "basic" },
            query: { to: "/payment-details" }
          });
        }
        if (key === "advance") {
          this.$router.push({
            name: "director-signup",
            params: { type: "advance" }
          });
        }
        if (key === "corporate") {
          this.$router.push({
            name: "director-signup",
            params: { type: "corporate" }
          });
        }
      }
    },
    submitHandler() {
      this.$vs.loading();
      this.submitContactInfo(this.formData)
        .then(res => {
          if (res.status === 200) {
            this.showMessage(
              "Success",
              "‘Your message has been sent to our Admins",
              "success"
            );
            this.$vs.loading.close();
            this.formData = {};
            this.$validator.reset();
          }
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.showMessage("Failed", err.response.data.message, "danger");
          }
          this.$vs.loading.close();
        });
    }
  },
  mounted() {
    if (this.$store.state.auth.isUserLoggedIn()) {
      this.isLogin = true;
    }

    if (this.$router.currentRoute["hash"]) {
      VueScrollTo.scrollTo(this.$router.currentRoute["hash"], 500);
    }
  },

  created() {
    //hero banner section
    this.fetchContentBySlug("pricing-page-banner-section-image").then(res => {
      this.heroBannerContent.image = res.data.data.content;
    });
    this.fetchContentBySlug("pricing-page-banner-section-button").then(res => {
      this.heroBannerContent.buttonText = res.data.data.buttonText;
      this.heroBannerContent.buttonUrl = res.data.data.buttonUrl;
    });
    this.fetchContentBySlug("pricing-page-banner-section-title").then(res => {
      this.heroBannerContent.title = res.data.data.content;
    });

    //text section
    this.fetchContentBySlug("pricing-page-text-section-title").then(res => {
      this.textSection.title = res.data.data.content;
    });
    this.fetchContentBySlug("pricing-page-text-section-description").then(
      res => {
        this.textSection.description = res.data.data.content;
      }
    );
    //second banner section
    /*this.fetchContentBySlug("pricing-page-hero-banner-2-section-image").then(res => {
        this.bannerContent.image = res.data.data.content;
      });*/
    this.fetchContentBySlug("pricing-page-hero-banner-2-section-button").then(
      res => {
        this.bannerContent.buttonText = res.data.data.buttonText;
        this.bannerContent.buttonUrl = res.data.data.buttonUrl;
      }
    );
    this.fetchContentBySlug("pricing-page-hero-banner-2-section-title").then(
      res => {
        this.bannerContent.title = res.data.data.content;
      }
    );
    this.fetchContentBySlug(
      "pricing-page-hero-banner-2-section-description"
    ).then(res => {
      this.bannerContent.description = res.data.data.content;
    });

    //contact form
    this.fetchContentBySlug("pricing-page-contact-form-section-title").then(
      res => {
        this.contactSection.title = res.data.data.content;
      }
    );
    this.fetchContentBySlug(
      "pricing-page-contact-form-section-description"
    ).then(res => {
      this.contactSection.description = res.data.data.content;
    });

    //faq
    this.fetchFaqDetails().then(res => {
      this.faqData = res.data.data;
    });

    //package section
    this.fetchPackageInfo("basic").then(res => {
      this.basicPackage = res.data.data;
    });
    this.fetchPackageInfo("advance").then(res => {
      this.advancePackage = res.data.data;
    });
    this.fetchPackageInfo("corporate").then(res => {
      this.corporatePackage = res.data.data;
    });
    this.fetchInclusionList().then(res => {
      this.options = res.data.data;
    });
  }
};
</script>
<style lang="scss">

.pre-formatted {
  white-space: pre-wrap;
}

#faq-page {
  .faq-jumbotron-content {
    //background-image: url('../../../assets/images/pages/faq.jpg');
    background-size: cover;
  }

  .faq-bg {
    background-color: #fff;
  }
}
#banner-page {
  .faq-jumbotron-content {
    background-size: cover;
    background-color: #74cac2;
  }
  h1 {
    @media (min-width: 768px) {
      font-size: 50px;
    }
  }
  p {
    font-size: 18px;
  }

  .faq-bg {
    background-color: cyan;
  }
}
#package-section {
  padding: 0 40px 40px;

  .package-card {
    min-height: 400px;
    margin: 20px;
  }

  .package-card-advanced {
    min-height: 440px;
    position: relative;
    .value {
      background-color: #2898d5;
      min-height: 50px;
      padding: 10px;
      position: relative;
      text-align: center;
      border-radius: 5px;

      @media (min-width: 768px) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
      }
    }
  }
}

.heading {
  text-align: center;
}

#contact-section {
  .contact-form-section {
    @media (min-width: 768px) {
      padding: 0 0 0 60px;
    }
  }
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #ffff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.round {
  position: relative;
  padding-left: 35px;
}
.round .material-icons {
  color: #74cac2;
  font-size: 25px;
  position: absolute;
  left: 0;
}
.last-child {
  border-left: 1px solid #a5a5a5;
}
.contact-section-wrap {
  background-color: #fff;
}
.contact-section-wrap,
.faq-wrap,
.text-section {
  h1 {
    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 1;
    }
  }
  p {
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}
.vs-input--input.hasIcon:focus + .vs-input--placeholder,
.vs-con-input-label.is-label-placeholder.isFocus .vs-input--placeholder,
.vs-input--input.hasValue + .vs-placeholder-label,
.vs-input--input:focus + .vs-placeholder-label {
  padding-left: 15px !important;
}

.vs-con-textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.faq-wrap {
  .td-check {
    display: none;
  }
  .vs-con-table .vs-con-tbody {
    background: none;
    border: none;
  }
  .vs-table--tbody-table tr {
    background: none;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    font-size: 20px;
    color: #27378e;
    padding: 30px 0;
  }
  .vs-table--tbody-table .tr-expand td {
    padding: 0 30px 30px;
    border: none;
  }
  .tr-expand td .content-tr-expand,
  .tr-expand td .tr-expand--close {
    justify-content: start;
    border: none;
    padding: 0;
  }
  tr.tr-values.vs-table--tr.tr-table-state-null.selected.tr-expandedx td {
    border-bottom-color: transparent;
  }
}
</style>
